import React, { useState } from "react";
import ProjectCard from "./ProjectCard/ProjectCard";
import './Projects.scss'; // Importation du fichier CSS

function Projects() {
  const [selectedCategory, setSelectedCategory] = useState('Prog');

  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  const projects = [
    {
      id: 1,
      title: 'Programming',
      description: 'Projects focusing on various programming languages, libraries, and environments.',
      category: 'Prog'
    },
    {
      id: 2,
      title: 'Mathematics & Physics',
      description: 'Mathematical & Physics modeling and algorithms.',
      category: 'Math'
    },
    {
      id: 3,
      title: 'Theoretical and Miscellaneous',
      description: 'Exploration of theoretical concepts and miscellaneous projects.',
      category: 'Theory'
    }
  ];

  // Fonction pour obtenir le titre de la section en fonction de la catégorie sélectionnée
  const getSectionTitle = () => {
    const project = projects.find(p => p.category === selectedCategory);
    return project ? project.description : 'Description non trouvée';
  };

  return (
    <>
      <svg
        height="100%"
        width="100%"
        id="svg"
        viewBox="0 0 1440 400"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M 0,400 C 0,400 0,200 0,200 C 114.35714285714289,156.53571428571428 228.71428571428578,113.07142857142858 351,131 C 473.2857142857142,148.92857142857142 603.4999999999998,228.25 713,248 C 822.5000000000002,267.75 911.2857142857144,227.92857142857142 1029,210 C 1146.7142857142856,192.07142857142858 1293.3571428571427,196.03571428571428 1440,200 C 1440,200 1440,400 1440,400 Z"
          stroke="none"
          strokeWidth="0"
          fill="#151418ff"
          transform="rotate(-180 720 200)"
        ></path>
      </svg>
      <div className="ProjectWrapper" id="projects">
        <div className="Container">
          <div className="category-banner">
            <button
              className={`category-button ${selectedCategory === 'programming' ? 'active' : ''}`}
              onClick={() => handleCategoryChange('Prog')}
            >
              Programming
            </button>
            <button
              className={`category-button ${selectedCategory === 'math-physics' ? 'active' : ''}`}
              onClick={() => handleCategoryChange('Math')}
            >
              Mathematics & Physics
            </button>
            <button
              className={`category-button ${selectedCategory === 'theoretical' ? 'active' : ''}`}
              onClick={() => handleCategoryChange('Theory')}
            >
              Theoretical & Miscellaneous
            </button>
          </div>
          <div className="SectionTitle">{getSectionTitle()}</div>
          {/* Pass selectedCategory as prop */}
          <ProjectCard selectedCategory={selectedCategory} />
        </div>
      </div>

    </>
  );
}

export default Projects;
