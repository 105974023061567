// --------------------------------------- Project List
// --------------------------------------- Project List
export const ProjectList = [
  {
    id:1,
    img: "/image87.png",
    title: "GCEngine Graphics Rendering DirectX12",
    description: "During my 2023-2024 internship at G.Studio, I contributed to the development of the Gaming Campus engine. As the head of the rendering team, I worked on creating the graphics rendering API for game development. My role involved developing a range of features for 2D and 3D rendering.\n\nDate of creation : July 2024\n",
    tech_stack: ["C++", "DirectX12", "NvidiaSightGraphics", "Visual Studio 2022"],
    github_url: "",
    demo_url: "",
    project_demo:"e",
    images: ["/image87.png", "/image82.png", "/image78.png", "/image88.png", "/image86.png", "/image84.png", "/image77.png"],
    descriptions: [
      "Rendering primitives (2D, 3D, OBJ) with support for meshes and materials, spritesheets, font loading.",
      "Lighting : Support for directional, point, and spotlight lighting, with options for simple rendering or Deferred Shading Lighting (DSL).",
      "Post-Processing : Application of visual effects such as blur, bloom, and object/layer ID filtering.",
      "Particle System : Management and customization of particle systems with updates to multiple geometries within the same mesh.",
      "Cameras : Creation and configuration of cameras with various projection types (Orthographic, Perspective).",
      "Shaders: Support for custom shaders.",
      "Resource Management: Automated management of static and dynamic resources with performance optimization and error handling.",
      
    ],
    conclusion: "",
    is_video: false,
    isCarousel:false,
    carousel_images: [],
    video_link:"/video1.mp4",
    category: "Prog",
  },
  {
    id:2,
    img: "/FOND62.png",
    title: "3DVerse Game Application Prototype",
    description: "There is a prototype of little narative game using private Web Javascript Engine (not Mine) api, ReactJS for most part running on github, ExpressJS for api running on #codesandbox.\n\nDate of creation : December 2023\n",
    tech_stack: ["3DVerse", "ReactJS", "ExpressJS", "NodeJS", "Javascript"],
    github_url: "",
    demo_url: "",
    project_demo:"e",
    images: ["/FOND62.png"],
    descriptions: [],
    conclusion: "",
    is_video: true,
    isCarousel:false,
    carousel_images: [],
    video_link:"https://www.youtube.com/embed/IUWyueONOz0",
    category: "Prog",
  },
  {
    id:3,
    img: "/FOND61.png",
    title: "Unity 3D Arcade Shooter Game - Raygun Carnage",
    description: "In two weeks, with 3 developers, we made a arcade shooter game named Raygun Carnage, using Unity and Github Desktop.\n\nDate of creation : May 2024\n",
    tech_stack: ["Unity 3D", "C#", "Github Desktop"],
    github_url: "https://github.com/WilliamChapron/Topdown_Arcade_Shooter_3d_Unity",
    demo_url: "",
    project_demo:"e",
    images: ["/FOND61.png"],
    descriptions: [],
    conclusion: "",
    is_video: true,
    isCarousel:false,
    carousel_images: [],
    video_link:"https://www.youtube.com/embed/2QaswXFE2GA", 
    category: "Prog",
  },
  {
    id:4,
    img: "/FOND66.png",
    title: "CurveCraft Math Oxyplot Learning",
    description: "For a math project, i designed and developed code to work on curves, this was about learning interpolation of Hermite and Physical modeling for simulate forces acting on movement objects.\n\nDate of creation : May 2024\n",
    tech_stack: ["C#", "WinForm", "Oxyplot"],
    github_url: "https://github.com/WilliamChapron/CurveCraft",
    demo_url: "",
    project_demo:"e",
    images: ["/FOND65.png", "/FOND66.png", "/FOND67.png", "/FOND68.png"],
    descriptions: ["Implementation of Hermite interpolation techniques to interpolate curves based on data points (10 points & intervals only), we had to find them manually from image, we used GeoGebra to do that.", "", "Simulation of various forces acting on a submerged submarine nautilus, with various forces parameters such as weight and more, it was a movement prediction modeling represented by a curve", ""],
    conclusion: "",
    is_video: false,
    isCarousel:false,
    carousel_images: [],
    video_link:"/video2.mp4",
    category: "Math",
  },

  {
    id:5,
    img: "/FOND52.png",
    title: "DirectX 12 - 3D Rendering Engine & Physic Engine",
    description: "Development of a 3D rendering and physics engine in C++ with the DirectX 12 Graphics API\n\nDate of creation : February 2024\n",
    tech_stack: ["DX12 SDK & Librairies", "C++", "HLSL"],
    github_url: "https://github.com/WilliamChapron/DX12GameEngine",
    demo_url: "",
    project_demo:"e",
    images: ["/FOND51.png", "/FOND52.png", "/FOND53.png", "/FOND54.png"],
    descriptions: [
      "Enhanced rendering capabilities, including advanced features such as 3D object rendering, shader rendering, texture rendering, and skybox creation for immersive environments.",
      "Developed rendering features like 3D world space with projection view and camera functionalities for enhanced visualization.",
      "Expanded physics engine capabilities to include cube collision systems, object transformations (movement, rotation, scaling), providing a more dynamic environment.",
      "Designed a space shooter game featuring destructible planets, utilizing a script component system for added flexibility in game development.",
      "Implemented an Entity Component System (ECS) similar to Unity, allowing easy addition of components to world objects for attributes like transformation, texture shaders, scripts, meshes, and mesh renderers."
    ],
    conclusion: "don't hesitate to go look at my project on github. (Previous Page)",
    is_video: false,
    isCarousel:false,
    carousel_images: [],
    video_link:"/video1.mp4",
    category: "Prog",
  },

  {
    id:6,
    img: "/FOND1.png",
    title: "Real Estate App - Chapron-Immo Company",
    description: "This website is designed for a real estate agent to showcase their services and properties for sale. It provides a platform for users to explore and inquire about real estate listings. The website includes a user system, allowing users to create accounts and access personalized features. Users can browse through the available properties, save their favorites, and submit inquiries or requests using the provided forms.\n\nDate of creation : June 2023\n",
    tech_stack: ["React", "Express", "MySQL"],
    github_url: "",
    demo_url: "https://chapron-immo.fr",
    project_demo:"",
    is_video: false,
    isCarousel:false,
    carousel_images: [],
    video_link:"/video1.mp4",
    category: "Prog",
  },
  {
    id:7,
    img: "/FOND3.png",
    title: "Horizontal Scroller Game - Iron Bird",
    description: "We, along with three other developer, developed a horizontal scrolling game centered around the themes of birds and the future. In the game, players control a futuristic bird capable of traveling back in time to evade obstacles during its flight. The objective is to achieve the highest score by avoiding obstacles, eliminating enemy birds from both the past and the future, and ensuring you are not defeated by their attacks, either by collision or by their shots. Additionally, there are numerous other dangerous obstacles to contend with.\n\nDate of creation : March 2023\n",
    tech_stack: ["Python", "PyGame"],
    github_url: "https://github.com/Opacube/Iron-Bird",
    demo_url: "",
    project_demo:"e",
    images: ["/FOND15.png", "/FOND16.png", "/FOND17.png","/FOND18.png"],
    descriptions: [
      "You can play to Iron Bird, there are Start, Win, Game Over systems",
      "You can return to Past, there are various Enemy, Attack and Explosion",
      "There are Power-Ups and Bonus systems",
      "Various Obstacles and Level Patterns",
    ],
    conclusion: "",
    is_video: false,
    isCarousel:false,
    carousel_images: [],
    video_link:"/video1.mp4",
    category: "Prog",
  },
  {
    id:8,
    img: "/FOND41.png",
    title: "Client-server TCP/IP Socket in MultiThreading - Tic-tac-toe Game",
    description: "The project implements a tic-tac-toe game using a client-server architecture with TCP/IP sockets and a multi-threaded programming approach. The application creates a server that handles client connections and communicate with them and allow network play.\n\nDate of creation : February 2024\n",
    tech_stack: ["WinAPI", "Winsock2", "C++", "SFML"],
    github_url: "https://github.com/WilliamChapron/TicTacToe-Client-Server-Socket-MultiThreading",
    demo_url: "",
    project_demo:"e",
    images: ["/FOND41.png", "/FOND40.png"],
    descriptions : [
      "The project enables two clients playing tic-tac-toe game concurrently. Each client as a player can interact with the game interface to make moves and participate in the gameplay.",
      "The server handle GET requests from clients, providing responses for display to the clients and on a web interface. This indicates the server can communicate with socket in bidirectional way, it can receive client requests and respond to them, it can send data to clients whenever it wants by broadcast system", 
    ],
    conclusion: "You can visit the GitHub repository to discover the code. (Previous Page)",
    is_video: false,
    isCarousel:false,
    carousel_images: [],
    video_link:"/video1.mp4",
    category: "Prog",
  },

  {
    id: 9,
    img: "/FOND13.png",
    title: "Immersive 3D Application - Space Training Educational",
    description: "An immersive 3D serious game developed using HTML/CSS/JavaScript X 3DVerse Web Engine. Designed for educational purposes and training.\n\nDate of creation : October 2023\n",
    tech_stack: ["3DVerse", "HTML/CSS", "JS"],
    github_url: "",
    demo_url: "https://3dverse.com/",
    project_demo: "",
    images: [],
    descriptions : [
    ],
    conclusion: "",
    is_video: false,
    isCarousel:true,
    carousel_images: ["/FOND13.png", "/FOND12.png", "/FOND11.png"],
    video_link:"/video1.mp4",
    category: "Prog",
  },  
  

  {
    id: 10,
    img: "/FOND27.png",
    title: "2D SFML Engine - Bricks Breaker",
    description: "2D Engine and Bricks Breaker game built in C++ with SFML.\n\nDate of creation : October 2023\n",
    tech_stack: ["C++", "SFML", "STL"],
    github_url: "https://github.com/WilliamChapron/Brick-Breacker-SFML",
    demo_url: "",
    project_demo: "e",
    images: ["/FOND25.png", "/FOND29.png", "/FOND26.png", "/FOND27.png", "/FOND30.png", "/FOND28.png"],
    descriptions : [
      "This is a Bricks-Breacker made in C++ with SFML",
      "For this, I made a 2D engine that allows drawing, there's a collision system, a game object manager and an input manager", 
      "Shooting system with canon that have specific physic for his rotation",
      "There are bullet bounces on brick faces and borders",
      "The engine contains circles, rectangular collisions and collision events",
      "Bricks have lifetime and face detection",
    ],
    conclusion: "",
    is_video: false,
    isCarousel:false,
    carousel_images: [],
    video_link:"/video1.mp4",
    category: "Prog",
  },  
  {
    id:11,
    img: "/FOND55.png",
    title: "2D Pokemon RPG - C# .NET ",
    description: "Development of a 2D Colored Pokemon RPG. There are a rendering part for Map, Menu and HUD. And a complex gameplay part for fight, stats, strategy, quests, the constraint is that the game is exclusively on console, Also we have implemented unit tests with NUnit. \n\nDate of creation : May 2024\n",
    tech_stack: ["C#", "MSDN Console", ".NET 7.0 Framework", "NUnit"],
    github_url: "https://github.com/BenjaminBENON/ProjetPokemon",
    demo_url: "",
    project_demo:"e",
    images: ["/FOND60.png"],
    descriptions: [
    ],
    conclusion: "",
    is_video: true,
    isCarousel:false,
    carousel_images: [],
    video_link:"https://www.youtube.com/embed/jv7HsKfqrMo",    
    category: "Prog",
  },
  {
    id:12,
    img: "/FOND10.png",
    title: "Unreal Engine Personal Video Game Project",
    description: "Developing a video game using Unreal Engine 5 with a focus on C++ programming, i'm currently working on this project.",
    tech_stack: ["UE5", "C++"],
    github_url: "",
    demo_url: "",
    project_demo:"",
    is_video: false,
    isCarousel:false,
    carousel_images: [],
    video_link:"/video1.mp4",
    category: "Prog",
  }
];


// 3D VERSE / Video Engine / Math project / Unity


// Add DIRECT X
export const stackList = [
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/c/c-original.svg",
    name: "C",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/cplusplus/cplusplus-original.svg",
    name: "C++14 With&Without STL",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/csharp/csharp-original.svg",
    name: "C#",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg",
    name: "JS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
    name: "Python",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg",
    name: "HTML",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg",
    name: "CSS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
    name: "ReactJS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/express/express-original.svg",
    name: "ExpressJS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original.svg",
    name: "MySQL",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original.svg",
    name: "MongoDB",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/bootstrap/bootstrap-original.svg",
    name: "Bootstrap",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/materialui/materialui-original.svg",
    name: "Material UI",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/vscode/vscode-original.svg",
    name: "VS Code",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/git/git-original.svg",
    name: "Git",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/figma/figma-original.svg",
    name: "Figma",
  },
];
