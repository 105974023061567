import React from "react";
import { stackList } from "../../data/ProjectData";
import "./About.css";
import { Fade } from "react-reveal";

import {useRef, useEffect} from "react"


const About = () => {

  useEffect(() => {
    
  }, []);


  return (
    <div className="ContactWrapper" id="about">
      <style>
  {`
    .Fade {
      background-color: #1f1f1f; /* Couleur de fond sombre */
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5); /* Ombre autour de la section */
      margin-bottom: 20px; /* Marge en bas de chaque section */
      position: relative;
    }

    .Fade::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.5); /* Opacité à 50% */
      border-radius: 10px;
    }

    .Fade img {
      max-width: 100%;
      border-radius: 8px;
      border: 2px solid #3498db; /* Bordure bleue autour de l'image */
    }

    /* Ajoutez ces styles pour chaque section si nécessaire */
    .AboutBio::before,
    .Languages::before,
    .Education::before,
    .GlobalSkills::before,
    .FullStackWebDev::before,
    .GameDevSoftware::before,
    .SoftSkills::before,
    .Experiences::before,
    .Interests::before,
    .Technologies {
    }

    .SectionContent {
      margin-top: 10px;
    }

    .SubTitle {
      position: relative;
      color: #3498db;
      font-size: 1.5em;
    }
    
    .SubTitle::before {
      content: "\\2192"; /* Code Unicode pour une flèche vers la droite */
      margin-right: 5px;
      font-size: 1.2em;
    }



    .SectionParagraph {
      margin-bottom: 5px;
    }

    .Tech {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    .TechImg {
      max-width: 30px;
      margin-right: 10px;
    }
  `}
</style>
      <div className="Container">
        <h2 className="SectionTitle">About Me</h2>
        <div className="BigCard">
          <Fade duration={0} delay={0} easing="ease">
            <img className="Image" src="/backlogo2.png" alt="Fond de la page de williamdeveloper.com / WILLIAM CHAPRON / williamdeveloper" />
          </Fade>
          <div className="AboutBio">
            <Fade duration={0} delay={0} easing="ease">
              <h3 className="SubTitle">Personal Information</h3>
              <div className="SectionContent">
                <p className="SectionParagraph">William CHAPRON</p>
                <p className="SectionParagraph">Game Development Student - Gaming Campus</p>
                <p className="SectionParagraph">Freelance - WDS Agency</p>
                <p className="SectionParagraph">Lyon</p>
                <p className="SectionParagraph">williamchapronpro@gmail.com</p>
                <p className="SectionParagraph">0782448620</p>
              </div>
            </Fade>

            {/* Languages */}
            <Fade duration={0} delay={0} easing="ease">
              <h3 className="SubTitle">Languages</h3>
              <div className="SectionContent">
                <p className="SectionParagraph">English : B2</p>
                <p className="SectionParagraph">Italian : A2</p>
                <p className="SectionParagraph">Japanese : A2</p>
              </div>
            </Fade>

            {/* Degrees */}
            <Fade duration={0} delay={0} easing="ease">
              <h3 className="SubTitle">Education</h3>
              <div className="SectionContent">
                <p className="SectionParagraph">French BAC STMG (Information and Management System)</p>
                <p className="SectionParagraph">French National Brevet</p>
              </div>
            </Fade>

            {/* Skills */}
            <Fade duration={0} delay={0} easing="ease">
              <h3 className="SubTitle">Globals Skills</h3>
              <div className="SectionContent">
              <p className="SectionParagraph">- 3D Application Software & Game Development</p>
              <p className="SectionParagraph">- Software development (Desktop Application)</p>
                <p className="SectionParagraph">- Full-stack Web Development</p>
                <p className="SectionParagraph">- I'm learning math at school for 3D programming, and we're studying topics such as linear algebra, 3D transformations, vector spaces, matrix operations, systems of equations, and vector calculation.</p>
                <p className="SectionParagraph">- Rich managerial knowledge from 2 years of management, digital management science and information system management</p>
                <p className="SectionParagraph">- I have a fairly good level of English which allows me to speak and read people from world</p>
                <p className="SectionParagraph">- Curious and interested in new technologies of computer development</p>
              </div>
            </Fade>

            {/* Computer Skills - Full-stack Web Development */}


            <Fade duration={0} delay={0} easing="ease">
              <h3 className="SubTitle">Game Development</h3>
              <div className="SectionContent">
                <p className="SectionParagraph">Proficient in C++, C#, Javascript, Python programming</p>
                <p className="SectionParagraph">Using environnement and librairies specific to video games (Unreal Engine, Unity, DirectX 12, SFML, SDL2, 3DVerse, Pygame)</p>
                <p className="SectionParagraph">I Developed a 3D Game Engine using DirectX 12, including rendering and physics engines, and a space shooter game showing some of the engine possibility</p>
                <p className="SectionParagraph">Created various 2D games, which can be found in my projects (the last is a C# MSDN Console RPG Game)</p>
              </div>
            </Fade>

            {/* Computer Skills - Game Development / Software */}
            <Fade duration={0} delay={0} easing="ease">
              <h3 className="SubTitle">Software Development</h3>
              <div className="SectionContent">
                <p className="SectionParagraph">Developement of cross-platform software</p>
                <p className="SectionParagraph">Proficient in building desktop applications using the .NET framework and Electron, across various platforms such as Windows, macOS, and Linux.</p>    
                <p className="SectionParagraph">Implemented relational and NoSQL databases to store, manage, and effectively communicate with application data.</p>
                <p className="SectionParagraph">Utilized technologies including MySQL, MongoDB and Using ORM (Object-Relational Mapping) to be more effective (Mongoose, Sequelize)</p>
                <p className="SectionParagraph">Actively participated in some phases of the software development lifecycle, from initial architecturing and needs analysis to programming, testing and deployment.</p>
              </div>
            </Fade>

            <Fade duration={0} delay={0} easing="ease">
              <h3 className="SubTitle">Full-stack Web Development</h3>
                <div className="SectionContent">
                  <p class="SectionParagraph">Advanced proficient of React in full responsive and front-end app Express with EJS.</p>
                  <p class="SectionParagraph">Advanced proficient in API RESTful development with Express.</p>
                  <p class="SectionParagraph">Deployment of web infrastructure with Nginx under VPS.</p>
                  <p class="SectionParagraph">Common use of popular style UI (Boostrap, Mui).</p>
                  <p class="SectionParagraph">Proficient in relational and non-relational database management (MySQL, MongoDB).</p>
                </div>
            </Fade>

            <Fade duration={0} delay={0} easing="ease">
              <h3 className="SubTitle">Soft Skills</h3>
              <div className="SectionContent">
                <p className="SectionParagraph">Initiative Taker</p>
                <p className="SectionParagraph">Team worker</p>
                <p className="SectionParagraph">Curious</p>
                <p className="SectionParagraph">Passionate and Hard Worker</p>
                <p className="SectionParagraph">Open-Minded</p>
                <p className="SectionParagraph">Introspective</p>
              </div>
            </Fade>


            {/* Experiences */}
            <Fade duration={0} delay={0} easing="ease">
              <h3 className="SubTitle">Experiences</h3>
              <div className="SectionContent">
                <p className="SectionParagraph">May 2023 Two-month internship - Creation of a dynamic website for a real estate agency (Chapron-Immo)</p>
                <p className="SectionParagraph">Logistics Working at “La Vie Claire” (Expedition sector)</p>
                <p className="SectionParagraph">Seasonal jobs as a fruit picker</p>
                <p className="SectionParagraph">Internship in a web/print graphics designer company</p>
                <p className="SectionParagraph">Managerial analyses of a motion design company and a game development company in Lyon</p>
                <p className="SectionParagraph">I am determined and not afraid to work</p>
              </div>
            </Fade>

            {/* Interests */}
            <Fade duration={0} delay={0} easing="ease">
              <h3 className="SubTitle">Interests</h3>
              <div className="SectionContent">
                <p className="SectionParagraph">Computer science, Video Games, Mathematics/Physics, Hiking/Running, Boxing</p>
              </div>
            </Fade>

            {/* Technologies */}
            <div className="Technologies">
              {stackList.map((stack, index) => (
                <Fade key={index}>
                  <div className="Tech" key={index}>
                    <img className="TechImg" src={stack.img} alt={stack.name} />
                    <div className="TechName">{stack.name}</div> 
                  </div>
                </Fade>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
