import React from "react";
import Hero from "../components/Hero/Hero";
import Projects from "../components/Projects/Projects";
import About from "../components/About/About";
import Contact from "../components/Contact/Contact";
import Footer from "../components/Footer/Footer";
import FixSocialIcon from "../components/SocialIcon/FixSocialIcon";
import ScrollToTop from "../components/SocialIcon/ScrollToTop";
// import Contributions from "../components/Contributions/Contributions";
import { Helmet } from 'react-helmet';

import "./Home.css"


function Home() {
  return (
    <>
      <Helmet>
        <title>William CHAPRON - Game Development Student and Freelance Web Developer</title>
        <meta name="description" content="Learn more about William CHAPRON, a Game Development student at Gaming Campus and Freelance Web Developer based in Lyon. Skills, education, languages, and work experiences included." />
        <meta name="keywords" content="William CHAPRON, williamdeveloper.com, williamdeveloper, WILLIAMDEVELOPER, entreprise.williamdeveloper.com, WDS Agency, CHAPRON William, Game Development, Web Development, Freelance, Lyon, Skills, Education, Languages" />
        <meta name="robots" content="index,follow" />
        <meta name="googlebot" content="index,follow,all" />
        <meta name="googlebot-news" content="index,follow" />
        <meta name="googlebot-video" content="index,follow" />
        <meta name="googlebot-image" content="index,follow" />
        <meta name="googlebot-mobile" content="index,follow" />
        <meta name="googlebot-news" content="index,follow" />
        <meta name="googlebot-video" content="index,follow" />
        <meta name="googlebot-image" content="index,follow" />

        <meta name="google" content="index,follow,all" />
        <meta name="revisit-after" content="1 days" />
      </Helmet>
      <div class="stars">
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>

      </div>
      <Hero />
      <Projects />
      <About />
      {/* <Contributions /> */}
      <Contact />      
      <FixSocialIcon />
      <Footer />
      <ScrollToTop />
    </>
  );
}

export default Home;
